<template>
  <div>
    <b-button variant="primary"
              size="sm"
              class="mb-50"
              @click="openScheduleModal(defaultScheduleItem)"
    >
      {{ $t('AddPaymentSchedules') }}
    </b-button>
    <!-- Content -->
    <!-- eslint-disable -->
    <b-card>
      <sw-table
        table-id="2645411d81567e412354f3dc7ff4b3aafcf"
        show-columns
        show-search
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="initData"
        @set-fields="fields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="tableFields"
            striped
            responsive
            :style="{ minHeight: tableSize(items, 250) }"
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); initData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <template #cell(type)="{ value }">
              <b-badge>
                <!--                <sw-icon :icon="getType(value).icon" />-->
                {{ $t(value) }}
              </b-badge>
            </template>
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <template #cell(action)>
              <b-dropdown
                id="dropdown-grouped"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <div>
                  <b-dropdown-item>
                    <dropdown-item-label icon="PauseIcon"
                                         :label="$t('Hold')"
                    />
                  </b-dropdown-item>
                    <b-dropdown-item>
                    <dropdown-item-label icon="PlayIcon"
                                         :label="$t('Resume')"
                    />
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <dropdown-item-label icon="EditIcon"
                                         :label="$t('EditSchedule')"
                    />
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <b-modal v-model="scheduleModal.show"
             :title="$t(scheduleModal.item && !scheduleModal.item.id ? 'Create' : 'Edit')"
             size="lg"
             hide-footer
    >
      <PaymentScheduleForm
        v-if="scheduleModal.show"
        :edited-item="scheduleModal.item"
      />
    </b-modal>
  </div>
</template>

<script>
import PaymentScheduleForm from '@/pages/contact/components/PaymentScheduleForm.vue'
import { BTable } from 'bootstrap-vue'

const defaultScheduleItem = {
  name: '',
}

export default {
  name: 'PaymentSchedule',
  components: { PaymentScheduleForm, BTable },
  data: () => ({
    loading: false,
    items: [
      {
        netAmount: '2000PLN',
        documentGenerationDate: '5. dzień miesiąca',
        generatedDocumentType: 'Pro forma',
        scheduleType: 'Google Ads',
        documentIssueMode: 'Wielokrotnie w miesiącu',
        documentsPerMonth: '5',
        automaticVatInvoiceGeneration: true,
        paymentDue: '7 dni',
        scheduleEndDate: '',
        scheduleStatus: 'Aktywny',
      },
    ],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',
    defaultScheduleItem,
    scheduleModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultScheduleItem)),
      index: -1,
    },
    fields: [
      { key: 'netAmount', label: 'NetAmount', visible: true },
      {
        key: 'documentGenerationDate', label: 'DocumentGenerationDate', visible: true, tag: 'b-badge',
      },
      {
        key: 'generatedDocumentType', label: 'GeneratedDocumentType', visible: true, tag: 'b-badge',
      },
      {
        key: 'scheduleType', label: 'ScheduleType', visible: true, tag: 'b-badge',
      },
      {
        key: 'documentIssueMode', label: 'DocumentIssueMode', visible: true, tag: 'b-badge',
      },
      {
        key: 'documentsPerMonth', label: 'DocumentsPerMonth', visible: true, tag: 'b-badge',
      },
      {
        key: 'automaticVatInvoiceGeneration', label: 'AutomaticVatInvoiceGeneration', visible: true,
      },
      {
        key: 'paymentDue', label: 'PaymentDue', visible: true, tag: 'b-badge',
      },
      { key: 'scheduleEndDate', label: 'ScheduleEndDate', visible: true },
      {
        key: 'scheduleStatus', label: 'ScheduleStatus', visible: true, tag: 'b-badge',
      },
      {
        key: 'action', label: 'Action', visible: true,
      },
    ],
  }),
  computed: {
    visibleFields() {
      return this.fields.filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },
  methods: {
    openScheduleModal(item = defaultScheduleItem, index = -1) {
      this.$set(this, 'scheduleModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    initData() {},
  },
}
</script>

<style scoped>

</style>
