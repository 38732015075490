<template>
  <div>

    <b-tabs>

      <b-tab :title="$t('ConnectWithGoogleAccount')">
        <sw-select :name="$t('SelectExistedAccount')">
          <v-select v-model="item.googleAccounts"
                    multiple
                    :options="googleAccounts"
          />
        </sw-select>
      </b-tab>

      <b-tab :title="$t('CreateNewAccount')" />

    </b-tabs>

    <!--    <b-row>-->
    <!--      <b-col sm="12">-->
    <!--        <sw-select :name="$t('CompanyData')">-->
    <!--          <v-select-->
    <!--            v-model="item.organization"-->
    <!--            :options="companies"-->
    <!--            label="name"-->
    <!--            :filterable="false"-->
    <!--            @search="reloadOrganizations"-->
    <!--          >-->
    <!--            <template #no-options="{ search }">-->
    <!--              <span>-->
    <!--                <span v-if="search.length">-->
    <!--                  {{ $t('NoData') }}-->
    <!--                </span>-->
    <!--                <span v-else>-->
    <!--                  {{ $t('TypeToSearch') }}-->
    <!--                </span>-->
    <!--              </span>-->
    <!--            </template>-->
    <!--          </v-select>-->
    <!--        </sw-select>-->
    <!--      </b-col>-->
    <!--      <b-col lg="3">-->
    <!--        <sw-select :name="$t('vatIdType')">-->
    <!--          <v-select-->
    <!--            v-model="item.vatIdType"-->
    <!--            label="country"-->
    <!--            :options="vatIdTypeList"-->
    <!--            :clearable="false"-->
    <!--            :filter="(options, search) => filterByKey(options, search, 'country', 'countryCodes.')"-->
    <!--          >-->
    <!--            <template #selected-option="{ country, value }">-->
    <!--              <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>-->
    <!--            </template>-->

    <!--            <template #option="{ country, value }">-->
    <!--              <span><strong>{{ $te(`countryCodes.${country}`) ? $t(`countryCodes.${country}`) : country }}</strong> {{ value.replaceAll('_', ' ').toUpperCase() }}</span>-->
    <!--            </template>-->

    <!--            <template #no-options="{ search }">-->
    <!--              <span>-->
    <!--                <span v-if="search.length">-->
    <!--                  {{ $t('NoData') }}-->
    <!--                </span>-->
    <!--                <span v-else>-->
    <!--                  {{ $t('TypeToSearch') }}-->
    <!--                </span>-->
    <!--              </span>-->
    <!--            </template>-->
    <!--          </v-select>-->
    <!--        </sw-select>-->
    <!--      </b-col>-->

    <!--      <b-col lg="9">-->
    <!--        &lt;!&ndash; Nip &ndash;&gt;-->
    <!--        <label>{{ $t('NIP') }}</label>-->

    <!--        <b-input-group-->
    <!--          class="mb-50"-->
    <!--        >-->
    <!--          <b-input-group-prepend v-if="nipExists">-->
    <!--            <b-button-->
    <!--              v-b-tooltip-->
    <!--              variant="outline-warning"-->
    <!--              :title="$t('TypedVatIdAlreadyExists')"-->
    <!--            >-->
    <!--              <feather-icon-->
    <!--                icon="AlertCircleIcon"-->
    <!--                size="12"-->
    <!--              />-->
    <!--            </b-button>-->
    <!--          </b-input-group-prepend>-->

    <!--          <b-form-input-->
    <!--            v-model="item.vatId"-->
    <!--            type="text"-->
    <!--            @blur="checkExistedNip"-->
    <!--          />-->

    <!--          <b-input-group-append v-if="item.vatIdType && item.vatIdType.country === 'Poland'">-->
    <!--            <b-button variant="outline-primary"-->
    <!--                      @click="downloadFromGus"-->
    <!--            >-->
    <!--              {{ $t('DownloadFromCSO') }}-->
    <!--            </b-button>-->
    <!--          </b-input-group-append>-->
    <!--        </b-input-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('LoadRules') }}
        <sw-icon v-b-tooltip
                 icon="AlertCircleIcon"
                 :title="$t('LoadRulesTip')"
        />
      </div>
    </div>

    <sw-select :name="$t('AccountType')">
      <v-select v-model="item.topUpMode"
                :reduce="e => e.type"
                :options="accountTypes"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <div class="d-flex align-items-end">
      <b-form-group :label="$t('AddAdPriceBudget')"
                    class="w-100"
      >
        <b-form-input v-model="item.adBudgetLimit"
                      type="number"
        />
      </b-form-group>
      <sw-select style="max-width: 200px"
                 class="ml-50"
      >
        <v-select v-model="item.adBudgetLimitCurrency"
                  :options="currencyList"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </div>

    <b-form-group>
      <b-form-checkbox v-model="item.generateProForma"
                       variant="primary"
      >
        {{ $t('GenerateProFormInvoice') }}
      </b-form-checkbox>
    </b-form-group>

    <sw-select :name="$t('ProFormInvoiceDay')">
      <sw-select-fpr>
        <flat-pickr v-model="item.proFormaExposureDay"
                    class="form-control"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
        />
      </sw-select-fpr>
    </sw-select>

    <b-form-group>
      <b-form-checkbox :checked="item.topUpMode === 'ONE_TIME'"
                       disabled
                       variant="primary"
      >
        {{ $t('OnceAMonethAddBugetToAccount') }}
      </b-form-checkbox>
    </b-form-group>

    <sw-info-alert v-if="item.topUpMode === 'ONE_TIME'"
                   :name="$t('GoogleAccountWillBeChargedAutomaticaly')"
    />

    <div>
      <b-form-group>
        <b-form-checkbox :checked="item.topUpMode === 'MANY_TIMES'"
                         disabled
                         variant="primary"
        >
          {{ $t('ChargeMultipleTimes') }}
        </b-form-checkbox>
      </b-form-group>
      <div v-if="item.topUpMode === 'MANY_TIMES'">
        <b-form-group
          class="ml-50"
          :label="$t('MonthChargesCount')"
        >
          <b-form-input v-model="item.monthChargesCount" />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="item.chargeAccountAfterInvoicePaid"
                           variant="primary"
          >
            {{ $t('ChargeAccountAfterInvoicePaidStatus') }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('SpecificationOfProForma') }}
      </div>
    </div>

    <b-form-group>
      <template #label>
        {{ $t('ServiceName') }}
        <sw-icon v-b-tooltip
                 icon="AlertCircleIcon"
                 :title="$t('ServiceNameTip')"
        />
      </template>
      <b-form-input v-model="item.serviceName" />
    </b-form-group>
    <sw-select :name="$t('DateOfSellService')">
      <sw-select-fpr>
        <flat-pickr v-model="item.serviceSellDate"
                    class="form-control"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
        />
      </sw-select-fpr>
    </sw-select>
    <b-form-group :label="$t('RewardPercent')">
      <template #label>
        {{ $t('RewardPercent') }}
        <sw-icon v-b-tooltip
                 icon="AlertCircleIcon"
                 :title="$t('RewardPercentTip')"
        />
      </template>
      <b-input-group>
        <b-form-input v-model="item.percentageSalary"
                      type="number"
        />
        <b-input-group-append is-text>
          %
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group :label="$t('PaymentDueDate')">
      <b-form-input v-model="item.paymentDaysCount"
                    type="number"
      /></b-form-group>

    <div v-if="item.topUpMode === 'MANY_TIMES'">
      <div class="divider divider-left">
        <div class="divider-text text-primary">
          {{ $t('AutoTopUpGoogleAdsAccount') }}
        </div>
      </div>
      <b-form-group :label="$t('SetUsagePercentageConditionForTopUp')">
        <b-input-group>
          <b-form-input
            v-model="item.chargeAccountPercentageCondition"
            type="number"
          />
          <b-input-group-append is-text>
            %
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <div>
      <div class="divider divider-left">
        <div class="divider-text text-primary">
          {{ $t('SetCampaignEndDate') }}
        </div>
      </div>
    </div>

    <!-- Określ dzień zakończenia kampanii -->
    <b-form-group>
      <b-form-checkbox v-model="item.turnOffCampaign"
                       variant="primary"
      >
        {{ $t('DoNotAutomaticallyTurnOffCampaign') }}
      </b-form-checkbox>
    </b-form-group>

    <sw-select :name="$t('CampaignEndDate')">
      <sw-select-fpr>
        <flat-pickr v-model="item.accountEndDate"
                    class="form-control"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
        />
      </sw-select-fpr>
    </sw-select>

    <sw-info-alert :name="$t('InfoTooltip')" />

    <div>
      <sw-action-button ref="save-action"
                        label="Save"
                        siz="sm"
                        variant="primary"
                        @click="saveItem"
      />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { BInputGroup, BInputGroupAppend, VBTooltip } from 'bootstrap-vue'
import currency from '@/helpers/currency'
import flatPickr from 'vue-flatpickr-component'
import keysCuter from '@/helpers/keysCuter'
import axiosIns from '@/libs/axios'

export default {
  name: 'AddGoogleAdsForm',
  components: {
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
    },
  },

  data: () => ({
    // item: {
    //   googleAccounts: [],
    //   name: '',
    //   currency: 'PLN',
    //   type: 'STATIC',
    // },
    currencyList: currency(),

    accountTypes: [
      { type: 'ONE_TIME', label: 'OncePerMonth' },
      { type: 'MANY_TIMES', label: 'ManyTimesAMonth' },
    ],

    googleAccounts: [
      'Konto 1 Ads',
      'Konto 2 Ads',
    ],

    item: {
      id: null,
      name: '',
      topUpMode: 'ONE_TIME',
      adBudgetLimit: '',
      generateProForma: false,
      proFormaExposureDay: '',
      serviceName: '',
      serviceSellDate: '',
      percentageSalary: 0,
      turnOffCampaign: false,
      accountEndDate: '',
      chargeAccountPercentageCondition: '',
      chargeAccountAfterInvoicePaid: false,
      monthChargesCount: 2,
      paymentDaysCount: 7,
      adBudgetLimitCurrency: 'PLN',
    },
  }),

  watch: {
    editedItem: {
      deep: true,
      handler() {
        this.initEditedItem()
      },
    },
  },
  mounted() {
    this.initEditedItem()
  },

  methods: {
    initEditedItem() {
      if (this.editedItem) {
        Object.entries(this.editedItem).forEach(([key, value]) => {
          if (value?.date) {
            this.$set(this.item, key, value.date)
          } else {
            this.$set(this.item, key, value)
          }
        })
      }
    },
    async saveItem() {
      this.$refs['save-action'].setLoading(true)
      const copy = JSON.parse(JSON.stringify(this.item))

      keysCuter(copy)

      copy.adBudgetLimit = Number(copy.adBudgetLimit || 0)
      copy.paymentDaysCount = Number(copy.paymentDaysCount || 0)
      copy.percentageSalary = Number(copy.percentageSalary || 0)
      copy.chargeAccountPercentageCondition = Number(copy.chargeAccountPercentageCondition || 0)

      try {
        const resp = copy?.id ? await axiosIns.patch('1/googleAdsClientAccounts', { ...copy }) : await axiosIns.post('1/googleAdsClientAccounts', { ...copy, status: 'ACTIVE' })
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        this.$emit('save', { response: resp, item: copy })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.$refs['save-action'].setLoading(false)
      }
    },
  },
}
</script>

<style scoped>

</style>
