<template>
  <div>
    <sw-select :name="$t('ScheduleTypeSelection')">
      <v-select v-model="item.type"
                :reduce="e => e.type"
                :options="accountTypes"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <div class="d-flex align-items-end">
      <b-form-group :label="$t('EnterNetAmount')"
                    class="w-100"
      >
        <b-form-input type="number" />
      </b-form-group>
      <sw-select style="max-width: 200px"
                 class="ml-50"
      >
        <v-select v-model="item.currency"
                  :options="currencyList"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </div>

    <sw-select :name="$t('SelectDocumentType')">
      <v-select :reduce="e => e.type"
                :options="['Faktura pro forma', 'Faktura VAT']"
      >
        <template #selected-option="{ label }">
          {{ $t(label) }}
        </template>
        <template #option="{ label }">
          {{ $t(label) }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group>
      <b-form-checkbox>
        {{ $t('GenerateAutoVatInvoice') }}
      </b-form-checkbox>
    </b-form-group>

    <sw-select :name="$t('SpecifyStartDocumentDate')">
      <sw-select-fpr>
        <flat-pickr class="form-control"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
        />
      </sw-select-fpr>
    </sw-select>

    <sw-select :name="$t('IssueFrequency')">
      <v-select :options="['Wielokrotnie w miesiącu', '1 razy w miesiącu']">
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('DocumentsPerMonthCount')"
                  class="w-100"
    >
      <b-form-input type="number" />
    </b-form-group>

    <sw-select :name="$t('SalesDate')">
      <v-select :options="['Bieżący miesiąc']">
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <b-form-group :label="$t('PaymentTerm')"
                  class="w-100"
    >
      <b-form-input type="number" />
    </b-form-group>

    <div class="divider divider-left">
      <div class="divider-text text-primary">
        {{ $t('DocumentPositions') }}
      </div>
    </div>

    <div class="d-flex align-items-center">
      <b-form-group class="flex-grow-1 mr-50"
                    :label="$t('EnterServiceName')"
      >
        <b-form-input />
      </b-form-group>
      <b-form-group class="flex-grow-1 mr-50"
                    :label="$t('EnterServiceAmount')"
      >
        <b-form-input />
      </b-form-group>

      <b-button variant="primary"
                size="sm"
                @click="services.push({ name: `Usługa _ ${services.length}`, price: `${(services.length || 1) * 233}` })"
      >
        {{ $t('Add') }}
      </b-button>
    </div>

    <div class="mb-1">
      <b-list-group v-if="services.length">
        <b-list-group-item v-for="(service, index) in services"
                           :key="index"
                           class="d-flex justify-content-between align-items-center"
        >
          <div>
            {{ service.name }} <b-badge>{{ service.price }}</b-badge>
          </div>
          <div>
            <b-button variant="flat-danger"
                      size="sm"
                      @click="$delete(services, index)"
            >
              <sw-icon icon="TrashIcon" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <div
        v-else
        class="p-1 text-center text-primary"
      >
        <feather-icon icon="XCircleIcon" />
        {{ $t('NoData') }}
      </div>
    </div>

    <!-- Określ dzień zakończenia kampanii -->
    <b-form-group>
      <b-form-checkbox>
        {{ $t('SpecifyEndScheduleDate') }}
      </b-form-checkbox>
    </b-form-group>

    <sw-select :name="$t('EndScheduleDate')">
      <sw-select-fpr>
        <flat-pickr class="form-control"
                    :config="{ locale: getCalendarLocale($i18n.locale) }"
        />
      </sw-select-fpr>
    </sw-select>

    <sw-info-alert :name="$t('ScheduleEndInfo')" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'
import currency from '@/helpers/currency'
import flatPickr from 'vue-flatpickr-component'

//     "": "Rodzaj harmonogramu",
//     "": "Podaj wartość netto płatności w harmonogramie",
//     "": "Wybierz rodzaj dokumentu",
//     "": "Generuj automatycznie faktury VAT z opłaconych dokumentów faktura pro forma",
//     "": "Określ dzień startowy wystawienia dokumentu",
//     "": "Tryb wystawienia dokumentu",
//     "": "Określ ilość wystawianych dokumentów w miesiącu",
//     "": "Data sprzedaży usługi",
//     "": "Termin płatności",
//     "": "Pozycje dokumentu",
//     "": "Określ datę końcową harmonogramu",
//     "": "Data zakończenia harmonogramu",
//     "CreateSchedule": "Utwórz harmonogram",
//     "ScheduleEndInfo": "W tym dniu harmonogram zostanie wyłączony",

export default {
  name: 'AddGoogleAdsForm',
  components: {
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    editedItem: {
      default: null,
      required: true,
    },
  },

  data: () => ({
    services: [],
    item: {
      googleAccounts: [],
      name: '',
      currency: 'PLN',
    },
    currencyList: currency(),

    accountTypes: [
      { type: 'STATIC', label: 'Harmonogram własny' },
    ],

  }),

  watch: {
    editedItem: {
      deep: true,
      handler(nw) {
        console.log('nw', nw)
      },
    },
  },
  mounted() {
    console.log(this.editedItem)
  },

  methods: {
  },
}
</script>

<style scoped>

</style>
