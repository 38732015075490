<template>
  <div>
    <b-button variant="primary"
              size="sm"
              class="mb-50"
              @click="openGoogleConnectionModal(defaultConnectionItem)"
    >
      {{ $t('ConnectWithGoogleAccount') }}
    </b-button>
    <!-- Content -->
    <!-- eslint-disable -->
    <b-card>
      <sw-table
        table-id="268111d8194e412354f3dc6ff4b3aafcf"
        show-columns
        show-search
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="initData"
        @set-fields="fields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="tableFields"
            striped
            responsive
            :style="{ minHeight: tableSize(items, 250) }"
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); initData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <template #cell(type)="{ value }">
              <b-badge>
                <!--                <sw-icon :icon="getType(value).icon" />-->
                {{ $t(value) }}
              </b-badge>
            </template>
            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

              <template #cell(action)="{ item }">
                  <b-dropdown
                          id="dropdown-grouped"
                          dropleft
                          no-caret
                          variant="flat"
                  >
                      <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" />
                      </template>
                      <div>
                          <b-dropdown-item @click="openChangeStatusModal">
                              <dropdown-item-label icon="XIcon"
                                                   :label="$t('SuspendAccount')"
                              />
                          </b-dropdown-item>
                          <b-dropdown-item>
                              <dropdown-item-label icon="ArchiveIcon"
                                                   :label="$t('ArchiveAccount')"
                              />
                          </b-dropdown-item>
                          <b-dropdown-item>
                              <dropdown-item-label icon="PlayIcon"
                                                   :label="$t('ResumeAccount')"
                              />
                          </b-dropdown-item>
                          <b-dropdown-item @click="openGoogleConnectionModal(item)">
                              <dropdown-item-label icon="EditIcon"
                                                   :label="$t('EditAccountSettings')"
                              />
                          </b-dropdown-item>
                      </div>
                  </b-dropdown>
              </template>

            <!--      Empty      -->
            <template #empty>
              <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <!-- Add Modal -->
    <b-modal v-model="connectionModal.show"
             :title="$t(connectionModal.item && !connectionModal.item.id ? 'Create' : 'Edit')"
             size="lg"
             hide-footer
    >
      <AddGoogleAdsForm
        v-if="connectionModal.show"
        :edited-item="connectionModal.item"
        @save="saveModalForm"
      />
    </b-modal>

    <!-- Hold Modal -->
    <b-modal v-model="holdModal.show"
             :title="$t(holdModal.item && !holdModal.item.id ? 'ChangeAccountStatus' : 'ChangeAccountStatus')"
             size="lg"
             hide-footer
    >
            <b-form-group>
                <b-form-checkbox>
                    {{ $t('SpecifySuspensionDate') }}
                </b-form-checkbox>
            </b-form-group>

            <sw-select :name="$t('SuspensionDate')">
                <sw-select-fpr>
                    <flat-pickr class="form-control"
                                :config="{ locale: getCalendarLocale($i18n.locale) }"
                    />
                </sw-select-fpr>
            </sw-select>

        <sw-info-alert :name="$t('SuspensionPeriodInfo')" />

        <div class="divider divider-left">
            <div class="divider-text text-primary">
                {{ $t('SuspendedCampaigns') }}
            </div>
        </div>


        <b-list-group>
            <b-list-group-item>Kampania Testowa 1</b-list-group-item>
            <b-list-group-item>Kampania Testowa 2</b-list-group-item>
            <b-list-group-item>Kampania Testowa 3</b-list-group-item>
        </b-list-group>

    </b-modal>
  </div>
</template>

<script>

import AddGoogleAdsForm from '@/pages/contact/components/AddGoogleAdsForm.vue'
import { BTable } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

const defaultConnectionItem = {
  id: null,
  name: '',
  topUpMode: 'ONE_TIME',
  adBudgetLimit: '',
  generateProForma: false,
  proFormaExposureDay: '',
  serviceName: '',
  serviceSellDate: '',
  percentageSalary: 0,
  turnOffCampaign: '',
  accountEndDate: '',
  chargeAccountPercentageCondition: '',
  chargeAccountAfterInvoicePaid: false,
  monthChargesCount: 0,
  paymentDaysCount: 0,
  adBudgetLimitCurrency: 'PLN',
}
export default {
  name: 'GoogleAds',
  components: { BTable, AddGoogleAdsForm },
  props: {
    contact: {
      default: null,
      required: true,
    },
    thread: {
      default: null,
      required: true,
    },
  },
  data: () => ({
    defaultConnectionItem,
    connectionModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultConnectionItem)),
      index: -1,
    },
    holdModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultConnectionItem)),
      index: -1,
    },
    loading: false,
    items: [],
    fields: [
      { key: 'name', label: '', visible: true },
      { key: 'status', label: '', visible: true },
      { key: 'topUpMode', label: 'AccountType', visible: true },
      { key: 'adBudgetLimit', label: 'AddAdPriceBudget', visible: true }, // adBudgetLimitCurrency
      { key: 'generateProForma', label: 'GenerateProFormInvoice', visible: true },
      { key: 'proFormaExposureDay', label: 'ProFormInvoiceDay', visible: true },
      { key: 'serviceName', label: 'ServiceName', visible: true },
      { key: 'serviceSellDate', label: 'DateOfSellService', visible: true },
      { key: 'percentageSalary', label: 'RewardPercent', visible: true },
      { key: 'paymentDaysCount', label: 'PaymentDueDate', visible: true },
      { key: 'chargeAccountPercentageCondition', label: 'SetUsagePercentageConditionForTopUp', visible: true },
      { key: 'turnOffCampaign', label: 'DoNotAutomaticallyTurnOffCampaign', visible: true },
      { key: 'accountEndDate', label: 'CampaignEndDate', visible: true },
      { key: 'chargeAccountAfterInvoicePaid', label: 'ChargeAccountAfterInvoicePaidStatus', visible: true },
      { key: 'monthChargesCount', label: 'MonthChargesCount', visible: true },
      // { key: 'googleAccount', label: 'GoogleAccount', visible: true },
      // {
      //   key: 'campaignStatusInAccount', label: 'CampaignStatusInAccount', tag: 'b-badge', visible: true,
      // },
      // { key: 'budgetValueSpent', label: 'BudgetValueSpent', visible: true },
      // { key: 'budgetValue', label: 'BudgetValue', visible: true },
      // {
      //   key: 'activeCampaignsCount', label: 'ActiveCampaignsCount', visible: true, tag: 'b-badge',
      // },
      // {
      //   key: 'accountTopUpMode', label: 'AccountTopUpMode', tag: 'b-badge', visible: true,
      // },
      // {
      //   key: 'topUpCondition', label: 'TopUpCondition', tag: 'b-badge', visible: true,
      // },
      // {
      //   key: 'monthlyTopUpCount', label: 'MonthlyTopUpCount', tag: 'b-badge', visible: true,
      // },
      // {
      //   key: 'proFormaInvoicesIssued', label: 'ProFormaInvoicesIssued', tag: 'b-badge', visible: true,
      // },
      // {
      //   key: 'vatInvoicesIssued', label: 'VatInvoicesIssued', tag: 'b-badge', visible: true,
      // },
      // { key: 'accountEndDate', label: 'AccountEndDate', visible: true },
      // {
      //   key: 'chargeAccountPercentageCondition', label: 'RemunerationPercentage', tag: 'b-badge', visible: true,
      // },
      // {
      //   key: 'paymentDaysCount', label: 'PaymentDueDate', tag: 'b-badge', visible: true,
      // },
      {
        key: 'action', label: 'Action', visible: true,
      },
    ],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',
  }),
  computed: {
    visibleFields() {
      return this.fields.filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule))
    },
    tableFields() {
      return this.visibleFields.filter(field => field.visible)
    },
  },
  async mounted() {
    await this.initData()
  },
  methods: {
    async saveModalForm() {
      this.$set(this, 'connectionModal', {
        show: false,
        item: JSON.parse(JSON.stringify(defaultConnectionItem)),
        index: -1,
      })

      this.initData()
    },
    openGoogleConnectionModal(item = defaultConnectionItem, index = -1) {
      this.$set(this, 'connectionModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    openChangeStatusModal(item = defaultConnectionItem, index = -1) {
      this.$set(this, 'holdModal', {
        show: true,
        item: JSON.parse(JSON.stringify(item)),
        index,
      })
    },
    async initData() {
      try {
        const params = {
          fields_load: 'GOOGLE_ADS_CLIENT_ACCOUNT',
        }
        const resp = await axiosIns.get('1/googleAdsClientAccounts', { params })
        console.log(resp)

        this.items = resp.data.data?.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style scoped>

</style>
