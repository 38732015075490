<template>
  <div v-if="checkRequiredPermissions([$roles.FULL_ACCESS])">
    <b-tabs>
      <b-tab :title="$t('Payments')">
        <expand-window
          :title="$t('FullWindow')"
        >
          <schedules
            :allow-filters="false"
            :thread-id="getObjectId(thread)"
            :contact-id="thread.contact.id"
          />
        </expand-window>
      </b-tab>
      <b-tab :title="$t('PaymentSchedule')">
        <PaymentSchedule
          :thread-id="getObjectId(thread)"
          :contact-id="thread.contact.id"
        />
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <expand-window
      :title="$t('FullWindow')"
    >
      <schedules
        :allow-filters="false"
        :thread-id="getObjectId(thread)"
        :contact-id="thread.contact.id"
      />
    </expand-window>
  </div>
</template>

<script>
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Schedules from '@/pages/Schedules.vue'
import PaymentSchedule from '@/pages/contact/components/PaymentSchedule.vue'

export default {
  components: { PaymentSchedule, Schedules, ExpandWindow },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
