<template>
  <!--  Selected Thread  -->
  <div v-if="protectGetThreads && router.currentRoute.params.threadId">
    <div v-if="!loading && thread">
      <!--    START::Change Thread and Funnel Process    -->
      <div
        class="d-flex flex-wrap align-items-center mb-50"
        style="gap: .34rem"
      >
        <!--    START::Threads    -->
        <b-button
          id="p-thread"
          v-b-tooltip.left.hover.v-primary
          variant="flat-primary"
          class="p-25 d-flex flex-row align-items-center gap-0.34 font-medium-3"
          :style="!protectGetThreads && 'cursor: default !important;'"
          :title="`${$t('Thread')}: ${thread.name}`"
        >
          <!--    START::Is Primary    -->
          <sw-icon
            v-if="thread.isPrimary"
            v-b-tooltip.left.hover.v-primary
            icon="fa-heart"
            class="text-danger"
            :title="$t('PrimaryThread')"
          />
          <!--    END::Is Primary    -->

          {{ thread.name | truncate(24) }}

          <!--    START::Dropdown icon    -->
          <sw-icon
            v-if="protectGetThreads"
            icon="ChevronDownIcon"
            size="20"
          />
          <!--    END::Dropdown icon    -->
        </b-button>

        <b-popover
          v-if="protectGetThreads"
          target="p-thread"
          triggers="focus, click"
          custom-class="p-0 mt-n75"
          placement="bottom"
          @show="$root.$emit('bv::hide::tooltip', 'p-thread')"
        >
          <template #title>
            {{ $t('Thread') }}
          </template>

          <b-list-group
            flush
            class="overflow-auto"
            style="max-height: 60vh"
          >
            <b-list-group-item
              v-for="threadItem in threadList"
              :key="threadItem.id"
              v-b-tooltip.left.hover.v-primary.top
              :disabled="threadItem.id === thread.id"
              class="d-flex align-items-center gap-0.34 w-100"
              button
              :title="threadItem.name"
              @click="changeActiveThread(threadItem)"
            >
              <!--    START::Is Primary    -->
              <sw-icon
                v-if="threadItem.isPrimary"
                v-b-tooltip.left.hover.v-primary
                icon="fa-heart"
                class="text-danger"
                :title="$t('PrimaryThread')"
              />
              <!--    END::Is Primary    -->

              {{ threadItem.name | truncate(24) }}

              <b-avatar
                v-if="threadItem.fromAssignedContact"
                v-b-tooltip.left.hover.v-primary
                variant="primary"
                :title="getUserName(threadItem.contact)"
                :text="`${threadItem.contact.firstName.slice(0, 1).toUpperCase()}${threadItem.contact.lastName.slice(0, 1).toUpperCase()}`"
                size="sm"
              />
            </b-list-group-item>

            <b-list-group-item
              v-if="!threadList.length"
              class="d-flex align-items-center gap-0.34"
            >
              {{ $t('EmptyArray') }}
            </b-list-group-item>

            <template v-if="protectAddThread">
              <b-list-group-item
                link-class="d-flex align-items-center gap-0.34"
                button
                class="text-primary mt-50 w-100"
                @click="mAddThread"
              >
                <sw-icon
                  icon="PlusIcon"
                  class="mt-n25"
                />

                {{ $t('AddThread') }}
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-popover>
        <!--    END::Threads    -->

        <!--    START::End    -->
        <div
          class="d-flex flex-wrap align-items-center"
          style="gap: .34rem"
        >
          <strong class="font-medium-1 text-secondary">
            /
          </strong>

          <!--    START::Funnel Process    -->
          <b-button
            id="p-process"
            v-b-tooltip.left.hover.v-primary
            variant="flat-primary"
            class="p-25 d-flex flex-row align-items-center gap-0.34 font-medium-3"
            :style="!protectGetFunnelProcess && 'cursor: default !important;'"
            :title="`${$t('Funnels')}: ${thread.contactThreadFunnelProcessSelected ? thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.name : '-'}`"
          >
            <template v-if="thread.contactThreadFunnelProcessSelected">
              {{ thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.name | truncate(24) }}
            </template>

            <sw-icon
              v-else
              icon="MinusIcon"
              size="20"
            />

            <!--    START::Dropdown icon    -->
            <sw-icon
              v-if="protectGetFunnelProcess"
              icon="ChevronDownIcon"
              size="20"
            />
            <!--    END::Dropdown icon    -->
          </b-button>

          <b-popover
            v-if="protectGetFunnelProcess"
            target="p-process"
            triggers="focus, click"
            custom-class="p-0 mt-n75"
            placement="bottom"
            @show="$root.$emit('bv::hide::tooltip', 'p-process')"
          >
            <template #title>
              {{ $t('Funnels') }}
            </template>

            <vue-perfect-scrollbar :options="{ maxScrollbarLength: 60, }"
                                   style="max-height: 300px"
            >
              <b-list-group flush>
                <b-list-group-item
                  v-for="funnelItem in funnelProcessList"
                  :key="funnelItem.id"
                  v-b-tooltip.left.hover.v-primary.left
                  :disabled="thread.contactThreadFunnelProcessSelected && funnelItem.id === thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.id"
                  class="d-flex align-items-center gap-0.34 w-100"
                  button
                  :title="funnelItem.name"
                  @click="changeActiveFunnel(funnelItem)"
                >
                  <template v-if="existedFunnelProcess(funnelItem.id)">
                    <feather-icon
                      v-if="existedFunnelProcess(funnelItem.id).status === 'CLOSED_POSITIVE'"
                      class="text-success"
                      icon="CheckCircleIcon"
                    />

                    <feather-icon
                      v-else-if="existedFunnelProcess(funnelItem.id).status === 'CLOSED_NEGATIVE'"
                      class="text-danger"
                      icon="XCircleIcon"
                    />

                    <feather-icon
                      v-else-if="existedFunnelProcess(funnelItem.id).status === 'PAUSED'"
                      class="text-warning"
                      icon="PauseCircleIcon"
                    />

                    <feather-icon
                      v-else
                      class="text-primary"
                      icon="CircleIcon"
                    />
                  </template>

                  {{ funnelItem.name | truncate(24) }}
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>
          </b-popover>
          <!--    END::Funnel Process    -->

          <!--    START::Close Funnel Process    -->
          <b-button-group
            v-if="protectCloseThread && (thread.contactThreadFunnelProcessSelected && !getThreadStatus(thread.contactThreadFunnelProcessSelected.status))"
            size="sm"
          >
            <b-button
              v-b-tooltip.left.hover.v-success
              variant="outline-success"
              :title="$t('FinishAsSuccess')"
              class="btn-icon p-25"
              @click="mCloseNoteModal('POSITIVE')"
            >
              <sw-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-b-tooltip.left.hover.v-danger
              variant="outline-danger"
              :title="$t('FinishAsFailure')"
              class="btn-icon p-25"
              @click="mCloseNoteModal('REJECTED')"
            >
              <sw-icon icon="XIcon" />
            </b-button>
          </b-button-group>
          <!--    END::Close Funnel Process    -->
        </div>
        <!--    END::End    -->
      </div>
      <!--    END::Change Thread and Funnel Process    -->

      <!--    START::If Closed Alert    -->
      <b-alert
        v-if="thread.contactThreadFunnelProcessSelected && thread.contactThreadFunnelProcessSelected.status !== 'OPEN' && thread.contactThreadFunnelProcessSelected.status !== 'PAUSED'"
        show
        :variant="thread.contactThreadFunnelProcessSelected.status === 'CLOSED_POSITIVE' ? 'success' : 'danger'"
      >
        <div class="alert-heading">
          <div class="d-flex justify-content-between">
            {{ thread.contactThreadFunnelProcessSelected.status === 'CLOSED_POSITIVE' ? $t('funnel.status.COMPLETED') : $t('funnel.status.REJECTED') }}

            <div v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_END_STATUS])">
              <b-button
                v-b-tooltip.left
                size="sm"
                class="font-weight-bolder text-uppercase p-25"
                :variant="thread.contactThreadFunnelProcessSelected.status === 'CLOSED_POSITIVE' ? 'flat-success' : 'flat-danger'"
                :title="$t('OpenFunnelProcessButtonTooltip')"
                @click="changeCloseStatus(true)"
              >
                {{ $t('Open') }} {{ $t('Thread').toLowerCase() }}
              </b-button>

              <b-button
                v-b-tooltip.left
                size="sm"
                class="font-weight-bolder text-uppercase ml-25 p-25"
                :variant="thread.contactThreadFunnelProcessSelected.status === 'CLOSED_POSITIVE' ? 'flat-success' : 'flat-danger'"
                :title="$t('ContinueFunnelProcessButtonTooltip')"
                @click="changeCloseStatus(false, true)"
              >
                {{ $t('Continue') }} {{ $t('Thread').toLowerCase() }}
              </b-button>
            </div>
          </div>
        </div>

        <div class="alert-body">
          <div><span class="font-weight-bolder">{{ $t('CloseReason') }}</span>:
            <span v-if="thread.contactThreadFunnelProcessSelected.contactThreadStatusReason">
              {{ thread.contactThreadFunnelProcessSelected.contactThreadStatusReason.name }}
            </span>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </div>
          <div>
            <span class="font-weight-bolder">{{ $t('Description') }}</span>:
            <span v-if="thread.contactThreadFunnelProcessSelected.statusNote">
              {{ thread.contactThreadFunnelProcessSelected.statusNote }}
            </span>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </div>
        </div>
      </b-alert>
      <!--    END::If Closed Alert    -->

      <!--    START::Modal Open Thread    -->
      <b-modal
        v-model="showOpenProcess"
        hide-footer
        :title="$t('Open') + ' ' + $t('ContactThread').toLowerCase()"
        no-close-on-backdrop
      >
        <!-- Edited name  -->
        <sw-select :name="$t('funnel.SelectFunnel')">
          <v-select
            v-model="selectProcessFunnel"
            :options="funnelProcessList"
            :selectable="f => f.contactThreadFunnelTypePoints && f.contactThreadFunnelTypePoints.length"
            label="name"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>

            <template #option="item">
              <span
                v-if="existedFunnelProcess(item.id)"
                class="mr-25"
              >
                <span
                  v-if="existedFunnelProcess(item.id).status === 'CLOSED_POSITIVE'"
                  v-b-tooltip.left
                  :title="$t('funnel.status.COMPLETED')"
                >
                  <feather-icon
                    class="text-success"
                    icon="CheckCircleIcon"
                  />
                </span>
                <span
                  v-else-if="existedFunnelProcess(item.id).status === 'CLOSED_NEGATIVE'"
                  v-b-tooltip.left
                  :title="$t('funnel.status.REJECTED')"
                >
                  <feather-icon
                    class="text-danger"
                    icon="XCircleIcon"
                  />
                </span>
                <span
                  v-else-if="existedFunnelProcess(item.id).status === 'PAUSED'"
                  v-b-tooltip
                  :title="$t('Paused')"
                >
                  <feather-icon
                    class="text-warning"
                    icon="PauseCircleIcon"
                  />
                </span>
                <feather-icon
                  v-else
                  class="text-primary"
                  icon="CircleIcon"
                />
              </span>
              {{ item.name }}
            </template>
          </v-select>
        </sw-select>

        <b-alert
          v-if="thread && selectProcessFunnel && thread.contactThreadFunnelProcesses.find(fp => fp.contactThreadFunnelType.id === selectProcessFunnel.id)"
          show
          variant="primary"
        >
          <div class="alert-body">
            <!--          Wybrany lejk został już zamknięty, kontynuowanie spowoduje jego ponowne otwarcie-->
            {{ $t('SelectedProcessIsClosedAlready') }}
          </div>
        </b-alert>

        <b-button
          variant="success"
          :disabled="!selectProcessFunnel"
          @click="createdFunnelProcess"
        >
          {{ $t('Open') }}
        </b-button>
      </b-modal>
      <!--    END::Modal Open Thread    -->

      <b-modal
        v-model="showCloseNoteModal"
        hide-footer
        :title="$t('FinishAs') + ' ' + (closeNote.status !== 'REJECTED' ? $t('funnel.status.Success') : $t('funnel.status.Failure'))"
        no-close-on-backdrop
        @hide="closeNote.reason = emptyCloseNote.reason; closeNote.note = emptyCloseNote.note; closeNote.status = emptyCloseNote.status; $forceUpdate()"
      >
        <!-- Edited name  -->
        <sw-select :name="$t('CloseReason')">
          <v-select
            v-model="closeNote.reason"
            :options="closeNote.status !== 'REJECTED' ? getPositive : getNegative"
            label="name"
            :reduce="reason => reason.id"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>
        <b-form-group :label="$t('note.Description')">
          <b-form-textarea
            v-model="closeNote.note"
            rows="5"
          />
        </b-form-group>
        <b-button
          variant="success"
          :disabled="!closeNote.reason || !closeNote.note"
          @click="closeThreadFunnelProcess()"
        >
          {{ $t('Finish') }}
        </b-button>
      </b-modal>
    </div>

    <b-spinner
      v-else
      type="grow"
      small
      class="my-50 mx-auto"
      variant="primary"
    />
  </div>

  <!--  No selected Thread  -->
  <div v-else>
    <template v-if="!loading">
      <!--    START::Threads    -->
      <b-button
        id="p-thread"
        variant="flat-primary"
        class="p-25 d-flex flex-row align-items-center gap-0.34 font-medium-3"
        :style="!protectGetThreads && 'cursor: default !important;'"
      >
        {{ $t('SelectThread') }}

        <!--    START::Dropdown icon    -->
        <sw-icon
          v-if="protectGetThreads"
          icon="ChevronDownIcon"
          size="20"
        />
        <!--    END::Dropdown icon    -->
      </b-button>

      <b-popover
        v-if="protectGetThreads"
        target="p-thread"
        triggers="focus, click"
        custom-class="p-0"
        placement="bottom"
      >
        <template #title>
          {{ $t('Thread') }}
        </template>

        <b-list-group
          class="overflow-auto"
          style="max-height: 60vh"
          flush
        >
          <b-list-group-item
            v-for="threadItem in threadList"
            :key="threadItem.id"
            v-b-tooltip.hover.v-primary.left
            class="d-flex align-items-center gap-0.34"
            button
            :title="threadItem.name"
            @click="changeActiveThread(threadItem)"
          >
            <!--    START::Is Primary    -->
            <sw-icon
              v-if="threadItem.isPrimary"
              v-b-tooltip.hover.v-primary
              icon="fa-heart"
              class="text-danger"
              :title="$t('PrimaryThread')"
            />
            <!--    END::Is Primary    -->

            {{ threadItem.name | truncate(24) }}

            <b-avatar
              v-if="threadItem.fromAssignedContact"
              v-b-tooltip.hover.v-primary
              variant="primary"
              :title="getUserName(threadItem.contact)"
              :text="`${threadItem.contact.firstName.slice(0, 1).toUpperCase()}${threadItem.contact.lastName.slice(0, 1).toUpperCase()}`"
              size="sm"
            />
          </b-list-group-item>

          <b-list-group-item
            v-if="!threadList.length"
            class="d-flex align-items-center gap-0.34"
          >
            {{ $t('EmptyArray') }}
          </b-list-group-item>

          <template v-if="protectAddThread">
            <b-list-group-item
              link-class="d-flex align-items-center gap-0.34"
              button
              class="text-primary mt-50"
              @click="mAddThread"
            >
              <sw-icon
                icon="PlusIcon"
                class="mt-n25"
              />

              {{ $t('AddThread') }}
            </b-list-group-item>
          </template>
        </b-list-group>
      </b-popover>
      <!--    END::Threads    -->
    </template>

    <b-spinner
      v-else
      type="grow"
      small
      class="my-50 mx-auto"
      variant="primary"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE,
  CLOSE_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_CONTACT_THREAD_CLOSE_STATUS_REASONS, GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_FULL_CURRENT_CONTACT_THREAD_LIST,
  GET_FUNNELS, GET_THREAD,
  OPEN_MODAL,
  UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
} from '@/@constants/mutations'
import * as $roles from '@/helpers/permissions'
import Ripple from 'vue-ripple-directive'
import {
  BButtonGroup, BModal, BPopover, VBTooltip,
} from 'bootstrap-vue'
import * as $viewRanks from '@/helpers/viewPermissions'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import EventBus from '@/event-bus'
import {
  eChangeFunnelProcess,
  eChangeFunnelProcessStatus, eNewThread, eRemoveThread, eEditThread, eChangeThreadPrimaryStatus,
} from '@/@types/events'
import router from '@/router'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BButtonGroup, BModal, BPopover, vSelect, VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    router,

    loading: false,
    threadList: [],
    funnelProcessList: [],
    closeNote: {
      status: 'CLOSED_POSITIVE',
      reason: '',
      note: '',
    },
    threadCloseStatusReasons: [],
    emptyCloseNote: {
      status: 'CLOSED_POSITIVE',
      reason: '',
      note: '',
    },
    showCloseNoteModal: false,
    showOpenProcess: false,
    selectProcessFunnel: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      contact: 'contact/contact',
      thread: 'contact/thread',
    }),

    protectGetThreads() {
      return this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_VIEW_ALL, this.$roles.CONTACT_THREAD_VIEW_ASSIGNED, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE, this.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST])
    },
    protectAddThread() {
      return this.checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])
    },
    protectCloseThread() {
      return this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_END_STATUS])
    },
    protectGetFunnelProcess() {
      return this.checkRequiredPermissions([
        $roles.CONTACT_THREAD_EDIT_ALL,
        {
          role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: this.thread, userId: this.user.id, field: 'assignedUsers',
        },
        {
          role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: this.thread, userId: this.user.id, field: 'createdBy',
        },
      ]) && this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_STATUS_EDIT])
    },
    getNegative() {
      return (this.threadCloseStatusReasons || []).map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
    },
    getPositive() {
      return (this.threadCloseStatusReasons || []).map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
  },
  watch: {
    async contact() { if (this.protectGetThreads) await this.loadThreadList() },
    async thread() { if (this.protectGetFunnelProcess) await this.loadFunnelList() },
  },
  async mounted() {
    if (this.protectGetThreads) {
      if (this.contact) await this.loadThreadList()
      if (this.thread && this.protectGetFunnelProcess) await this.loadFunnelList()

      await this.loadThreadCloseStatusReasons()

      EventBus.$on(eChangeFunnelProcess, this.handleChangeFunnelProcess)
      EventBus.$on(eChangeFunnelProcessStatus, this.handleChangeFunnelProcessStatus)
      EventBus.$on(eNewThread, this.handleNewThread)
      EventBus.$on(eEditThread, this.handleEditThread)
      EventBus.$on(eRemoveThread, this.handleRemoveThread)
      EventBus.$on(eChangeThreadPrimaryStatus, this.handleChangeThreadPrimaryStatus)
    }
  },
  destroyed() {
    if (this.protectGetThreads) {
      EventBus.$off(eChangeFunnelProcess, this.handleChangeFunnelProcess)
      EventBus.$off(eChangeFunnelProcessStatus, this.handleChangeFunnelProcessStatus)
      EventBus.$off(eNewThread, this.handleNewThread)
      EventBus.$off(eEditThread, this.handleEditThread)
      EventBus.$off(eRemoveThread, this.handleRemoveThread)
      EventBus.$off(eChangeThreadPrimaryStatus, this.handleChangeThreadPrimaryStatus)
    }
  },
  methods: {
    ...mapActions({
      getThread: `contact/${GET_THREAD}`,
      getFullThreadList: `contact/${GET_FULL_CURRENT_CONTACT_THREAD_LIST}`,
      getThreadFunnelProcess: `contact/${GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS}`,
      changeActiveFunnelProcess: `contact/${CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE}`,
      updateThreadFunnelProcess: `contact/${UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS}`,
      addThreadFunnelProcess: `contact/${ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS}`,
      getContactThreadCloseStatusReasons: `contact/${GET_CONTACT_THREAD_CLOSE_STATUS_REASONS}`,
      closeContactThreadFunnelProcess: `contact/${CLOSE_CONTACT_THREAD_FUNNEL_PROCESS}`,

      getFunnelList: `funnelMain/${GET_FUNNELS}`,
    }),
    ...mapMutations({
      changeActiveThread: 'contact/changeActiveThread',
      updateCurrentThread: 'contact/updateCurrentThread',
      changeCurrentThreadPrimary: 'contact/changeCurrentThreadPrimary',
    }),

    async loadThreadList() {
      this.loading = true

      try {
        this.threadList = this.protectGetThreads ? await this.getFullThreadList() : []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    getThreadStatus(status) {
      switch (status) {
        case 'CLOSED_POSITIVE':
          return {
            status: 'COMPLETED',
            text: 'text-primary',
            variant: 'primary',
          }
        case 'CLOSED_NEGATIVE':
          return {
            status: 'REJECTED',
            text: 'text-danger',
            variant: 'danger',
          }
        default:
          return null
      }
    },
    async loadFunnelList() {
      this.loading = true

      try {
        this.funnelProcessList = await this.getFunnelList()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    mAddThread() {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        modalTitle: this.$i18n.t('AddThread'),
        okButtonTitle: this.$i18n.t('Save'),
        size: 'lg',
        contactId: this.contact.id,
        modalClass: 'thread-modal',
      })
    },
    async changeActiveFunnel(funnelProcessType) {
      const isCurrentOpen = this.thread.contactThreadFunnelProcesses.find(t => t.status === 'OPEN' && t.contactThreadFunnelType.id === funnelProcessType.id)
      const isTargetExist = this.thread.contactThreadFunnelProcesses.find(t => t.contactThreadFunnelType.id === funnelProcessType.id)

      if ((!isCurrentOpen && isTargetExist) || isCurrentOpen) {
        try {
          await this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(
              isTargetExist?.status === 'PAUSED' ? 'FromOpenToPaused' : 'ContinueFunnelProcessButtonTooltip',
          ))
        } catch (err) { return }

        try {
          await this.changeCloseStatus(false, true, isTargetExist.id)

          const nextFunnelProcess = JSON.parse(JSON.stringify(this.thread.contactThreadFunnelProcesses.find(({ contactThreadFunnelType }) => contactThreadFunnelType.id === funnelProcessType.id)))
          nextFunnelProcess.style = 'OPEN'

          EventBus.$emit(eChangeFunnelProcess, {
            thread: this.thread,
            funnelProcess: nextFunnelProcess,
          })
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }

        return
      }

      // create when (current is closed and target NOT exists)
      if ((!isCurrentOpen && !isTargetExist) || ['OPEN', 'PAUSED'].includes(this.thread.contactThreadFunnelProcessLast?.status)) {
        // Show second alert
        try {
          await this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(['OPEN', 'PAUSED'].includes(this.thread.contactThreadFunnelProcessLast?.status) ? 'OpenFunnelProcessButtonTooltipAndPauseCurrent' : 'OpenFunnelProcessButtonTooltip'))
        } catch (err) { return }

        // Change Active Funnel
        try {
          const payload = {
            contactThreadFunnelTypePoint: this.funnelProcessList.find(a => a.id === funnelProcessType.id).contactThreadFunnelTypePoints[0].id.toString(),
            status: 'OPEN',
          }

          const newThreadFunnelProcessId = await this.addThreadFunnelProcess(payload)

          const newThreadFunnelProcess = await this.getThreadFunnelProcess(newThreadFunnelProcessId)
          EventBus.$emit(eChangeFunnelProcess, { thread: this.thread, funnelProcess: newThreadFunnelProcess })
          // this.thread.contactThreadFunnelProcessSelected = newThreadFunnelProcess
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }

      // this.loadFunnelList()
    },
    async createdFunnelProcess() {
      const threadId = this.thread?.id
      try {
        const existed = this.thread.contactThreadFunnelProcesses.find(fp => fp.contactThreadFunnelType.id === this.selectProcessFunnel.id)
        if (existed) {
          const payload = {
            id: existed.id,
            statusNote: null,
            contactThreadStatusReason: null,
            status: 'OPEN',
          }

          await axiosIns.patch(`1/contacts/threads/${threadId}/funnelProcesses`, payload)

          EventBus.$emit(eChangeFunnelProcessStatus, { thread: this.thread, funnelProcess: payload })
        } else {
          const payload = {
            contactThreadFunnelTypePoint: this.selectProcessFunnel.contactThreadFunnelTypePoints[0].id.toString(),
            status: 'OPEN',
          }

          await axiosIns.post(`1/contacts/threads/${threadId}/funnelProcesses`, payload)

          EventBus.$emit(eChangeFunnelProcessStatus, { thread: this.thread, funnelProcess: payload })
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.selectProcessFunnel = ''
        this.showOpenProcess = false
      }
    },
    async changeCloseStatus(create = false, open = false, targetId = null) {
      try {
        const status = this.closeNote.status !== 'REJECTED' ? 'CLOSED_POSITIVE' : 'CLOSED_NEGATIVE'
        const funnelProcessId = targetId || this.thread?.contactThreadFunnelProcessSelected?.id

        let payload = {
          status,
          statusNote: this.closeNote.note,
          contactThreadStatusReason: this.closeNote.reason,
          id: funnelProcessId,
        }

        if (create) {
          this.selectProcessFunnel = ''
          this.showOpenProcess = true
          return
        }

        if (open) {
          payload = {
            status: 'OPEN',
            statusNote: null,
            contactThreadStatusReason: null,
            id: funnelProcessId,
          }
        }

        const res = await this.updateThreadFunnelProcess(payload)

        EventBus.$emit(eChangeFunnelProcessStatus, {
          thread: this.thread,
          funnelProcess: {
            id: res,
            ...payload,
          },
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    mCloseNoteModal(type) {
      this.closeNote.status = type
      this.showCloseNoteModal = true
    },
    async loadThreadCloseStatusReasons() {
      this.loading = true

      try {
        this.threadCloseStatusReasons = await this.getContactThreadCloseStatusReasons()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async closeThreadFunnelProcess() {
      const contactThreadStatusReason = this.getObjectId(this.closeNote.reason)
      const reason = this.threadCloseStatusReasons.find(r => r.id === contactThreadStatusReason)

      try {
        const { payload } = await this.closeContactThreadFunnelProcess({
          thread: this.thread,
          closeNote: this.closeNote,
          reason,
        })

        EventBus.$emit(eChangeFunnelProcessStatus, {
          thread: this.thread,
          funnelProcess: { ...payload, contactThreadStatusReason: reason },
        })

        this.showCloseNoteModal = false

        this.closeNote.reason = this.emptyCloseNote.reason
        this.closeNote.note = this.emptyCloseNote.note
        this.closeNote.status = this.emptyCloseNote.status
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

        this.closeNote.reason = this.emptyCloseNote.reason
        this.closeNote.note = this.emptyCloseNote.note
        this.closeNote.status = this.emptyCloseNote.status
      }
    },
    existedFunnelProcess(processId) {
      return this.thread.contactThreadFunnelProcesses?.find(({ contactThreadFunnelType }) => contactThreadFunnelType.id === processId)
    },
    async handleChangeFunnelProcess({ funnelProcess }) {
      const currentFunnelProcess = this.thread.contactThreadFunnelProcesses.find(({ id }) => id === this.thread.contactThreadFunnelProcessSelected.id) || this.thread.contactThreadFunnelProcessSelected
      const nextFunnelProcess = this.thread.contactThreadFunnelProcesses.find(({ id }) => id === funnelProcess.id) || funnelProcess

      if (currentFunnelProcess && nextFunnelProcess) {
        if (currentFunnelProcess.status !== 'CLOSED_POSITIVE' && currentFunnelProcess.status !== 'CLOSED_NEGATIVE') currentFunnelProcess.status = 'PAUSED'

        this.thread.contactThreadFunnelProcesses.forEach((e, index) => {
          if (e.id !== nextFunnelProcess.id && e.status !== 'CLOSED_POSITIVE' && e.status !== 'CLOSED_NEGATIVE') {
            this.$set(this.thread.contactThreadFunnelProcesses[index], 'status', 'PAUSED')
          }
        })

        const payload = JSON.parse(JSON.stringify(this.thread))

        nextFunnelProcess.status = 'OPEN'
        payload.contactThreadFunnelProcessLast = nextFunnelProcess
        payload.contactThreadFunnelProcessSelected = nextFunnelProcess

        if (!this.protectGetThreads) return

        this.updateCurrentThread(await this.getThread(this.thread.id))
      }
    },
    handleChangeFunnelProcessStatus({ funnelProcess }) {
      const t = this.thread.contactThreadFunnelProcesses.find(({ id }) => id === funnelProcess.id)

      if (t) {
        t.status = funnelProcess.status
        t.statusNote = funnelProcess.statusNote
        t.contactThreadStatusReason = funnelProcess.contactThreadStatusReason

        // Update current thread
        if (this.thread.contactThreadFunnelProcessSelected.id === funnelProcess.id) {
          this.thread.contactThreadFunnelProcessSelected.status = funnelProcess.status
          this.thread.contactThreadFunnelProcessSelected.statusNote = funnelProcess.statusNote
          this.thread.contactThreadFunnelProcessSelected.contactThreadStatusReason = funnelProcess.contactThreadStatusReason
        }
      }
    },
    async handleNewThread({ thread }) {
      if (!this.protectGetThreads) return
      const newThread = await this.getThread(thread.id)

      if (newThread) this.threadList.push(newThread)
    },
    async handleEditThread({ thread }) {
      if (!this.protectGetThreads) return
      const newThread = await this.getThread(thread.id)

      if (newThread) {
        const i = this.threadList.findIndex(({ id }) => id === newThread.id)

        if (i) {
          this.threadList[i] = newThread
          this.$forceUpdate()
        }

        if (newThread.id === thread.id) this.updateCurrentThread(newThread)
      }
    },
    async handleRemoveThread({ thread }) {
      const i = this.threadList.findIndex(({ id }) => id === thread.id)

      if (i) {
        this.threadList.splice(i, 1)
        this.$forceUpdate()

        if (thread.id === this.thread.id) this.changeActiveThread(this.threadList[0])
      }
    },
    async handleChangeThreadPrimaryStatus({ thread }) {
      const newPrimaryThread = this.threadList.find(({ id }) => id === thread.id)
      const oldPrimaryThread = this.threadList.find(({ isPrimary }) => isPrimary)

      if (newPrimaryThread) newPrimaryThread.isPrimary = thread.isPrimary
      if (oldPrimaryThread) oldPrimaryThread.isPrimary = false

      if (newPrimaryThread?.id === this.thread.id || oldPrimaryThread?.id === this.thread.id) this.changeCurrentThreadPrimary()

      this.$forceUpdate()
    },
  },
}
</script>
